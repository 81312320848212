import React from 'react';
import { useParams } from 'react-router-dom';
import TypeSelectPage from './TypeSelectPage';
import WriteTaxiPage from './WriteTaxiPage';
import TypeSelectTaxiPage from './TypeSelectTaxiPage';
import TypeSelectCommunityPage from './TypeSelectCommunityPage';
import ReplyDeliveryPage from './ReplyDeliveryPage';
import ReplyTaxiPage from './ReplyTaxiPage';
import ReplyCommunityPage from './ReplyCommunityPage';

function ViewWritePage(props) {
    const params=useParams()
    console.log("view write page>>>",params)
    if (params.tableType==="delivery-table") {
         return <ReplyDeliveryPage/>
         
    
    } else if(params.tableType==="taxi-table"){
        return <ReplyTaxiPage/>
    }
    else if(params.tableType==="community-table"){
        return <ReplyCommunityPage/>
    }
   
}

export default ViewWritePage;